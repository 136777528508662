import { Child } from "./child"

export class InfoPayment {

    discountDescription: string
    amountSubtotal: number
    amountDiscount: number
    amountDiscountBrothers: number
    amountTotal: number
    services: string
    invoices: string
    invoiceDefault: string
    mounths: number
    child: string
    arrayServices: string[]

    constructor(attrs?: string) {
        this.child = attrs
        this.clear();
    }

    clear() {
        this.discountDescription = ""
        this.amountSubtotal = 0
        this.amountDiscount = 0
        this.amountTotal = 0
        this.invoices = ""
        this.services = ""
        this.invoiceDefault = ""
        this.mounths = 0
        this.arrayServices = []
        this.amountDiscountBrothers = 0
    }
}
