import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { ISchoolResponse } from "@interfaces/admin/response/schools-response.interface";
import { ISchool } from "@interfaces/admin/school.interface";
import { School } from "@models/admin/school";
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

/**
* @autor Adrian Avendano Concha
* @date 20/01/2022
*/
@Injectable({
    providedIn: 'root'
})
export class SchoolsService {
    constructor(private httpClient: HttpClient) { }

    /**
    * @autor Adrian Avendano Concha
    * @date 20/01/2022
    */
    getActiveSchools() {
        return this.httpClient.get(`${environment.API_ENDPOINTS}/aolschools/schools`).pipe(
            map((response: ISchoolResponse) => {
                return response.schools.map((school: ISchool) => {
                    return new School(school)
                });
            }),
        )
    }

    getActiveSchoolsLandingKit(): Observable<any> {
        return this.httpClient.get<any>(`https://hyijne9qdh.execute-api.us-east-1.amazonaws.com/schools`);
        
    }

    
    getActiveSchoolsLandingKitPresencial(): Observable<any> {
       return this.httpClient.get<any>(`https://hyijne9qdh.execute-api.us-east-1.amazonaws.com/schools?query=presencial`);  
    }

   

   
    getGradesSchool(option:number){
        return this.httpClient.get(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?option=${option}`);  
    }

    getStudentSchool(option: number ,school_id){
        return this.httpClient.get(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?option=${option}&schoolId=${school_id}`);  
    }

    getEmailAndGenderStudent(option: number ,child_id){
        return this.httpClient.get(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?option=${option}&childId=${child_id}`);  
    }
    
    updateSchoolStudent(data: any, child_id: string, option: number ){
        return this.httpClient.post(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?childId=${child_id}&option=${option}`, data);  

    }

    updateGradeStudent(data: any, child_id: string, option: number ){
        return this.httpClient.post(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?childId=${child_id}&option=${option}`, data);  

    }

    updateDataGeneralStudent(data: any, child_id: string, option: number ){
        return this.httpClient.post(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?childId=${child_id}&option=${option}`, data);  

    }

    updateDataUser(data: any, child_id: string, option: number ){
        return this.httpClient.post(` https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/schoolsAndGradesStudent?childId=${child_id}&option=${option}`, data);  

    }


}