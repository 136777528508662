import { ISchool } from "@interfaces/admin/school.interface"

export class School {

    school_id: string
    name: string
    counter: number
    hubspot_pipeline_id: string
    hubspot_column_lead_id: string
    hubspot_column_existig_lead_id: string
    hubspot_column_prospect_id: string
    is_active: boolean

    constructor(attrs?: ISchool) {
        this.school_id = attrs.school_id
        this.name = attrs.name
        this.counter = attrs.counter
        this.hubspot_pipeline_id = attrs.hubspot_pipeline_id
        this.hubspot_column_lead_id = attrs.hubspot_column_lead_id
        this.hubspot_column_existig_lead_id = attrs.hubspot_column_existig_lead_id
        this.hubspot_column_prospect_id = attrs.hubspot_column_prospect_id
        this.is_active = attrs.is_active
    }
}
