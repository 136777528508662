import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { IChildren } from "@interfaces/admin/children.interface";
import { IChildrenResponse } from "@interfaces/admin/response/children-response.interface";
import { Children } from "@models/admin/children";
import { map } from "rxjs/operators";

/**
* @autor Adrian Avendano Concha
* @date 20/01/2022
*/
@Injectable({
    providedIn: 'root'
})
export class ChildrenService {
    constructor(private httpClient: HttpClient) { }

    /**
    * @autor Adrian Avendano Concha
    * @date 20/01/2022
    */
    getChildrenBySchool(school_id: string) {
        let url = `${environment.API_ENDPOINTS}/aolservices/children`;
        url += `?school=${school_id}`
        return this.httpClient.get(url).pipe(
            map((response: any) => {
                if (response.error) {
                    return { error: response.error }
                }
                return response.children.map((child: IChildren) => {
                    return new Children(child)
                });
            }),
        )
    }

    /**
    * @autor Adrian Avendano Concha
    * @date 20/01/2022
    */
    getChildrenByValue(value: string) {
        let url = `${environment.API_ENDPOINTS}/aolservices/children`;
        url += `?value=${value}`
        return this.httpClient.get(url).pipe(
            map((response: any) => {
                if (response.error) {
                    return { error: response.error }
                }
                return response.children.map((child: IChildren) => {
                    return new Children(child)
                });
            }),
        )
    }

    getDocusignSignings(child_id: string) {
        let url = `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/docusignSigningsStudent?childId=${child_id}`;
        return this.httpClient.get(url)
    }

    getDocumentPfd(child_id: string, envelope_id: string) {
        return this.httpClient.get(`https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/childrenSignesDocuments?childId=${child_id}&envelopeId=${envelope_id}`)
    }

    updateStateDocusign(docusing_signing_id: string, data: any) {
        return this.httpClient.post(`https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/docusignSigningsStudent?docusingSigningId=${docusing_signing_id}`, data)
    }

    signingDocuments(schoolId: string, schoolGrade: string) {
        return this.httpClient.get(`https://7153g3fst0.execute-api.us-east-1.amazonaws.com/Prod/enrollmentSheet?schoolId=${schoolId}&schoolGrade=${schoolGrade}`)

    }
    printDocuments(schoolId: string, schoolGrade: string, mergerPdf: boolean) {
        return this.httpClient.get(`https://7153g3fst0.execute-api.us-east-1.amazonaws.com/Prod/enrollmentSheet?schoolId=${schoolId}&schoolGrade=${schoolGrade}&mergerPdf=${mergerPdf}`)

    }
}