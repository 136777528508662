import { FormGroup } from '@angular/forms'
import { IBillingInfo } from '@interfaces/billing-info.interface'
import { ICreateTutors } from '@interfaces/create-tutors.interface'
import { IParentSchoolsPayload } from '@interfaces/parent-schools-payload.interface'
import { IUpdateParent } from '@interfaces/update-parent.interface'
import { IUpdatePersonalInfo } from '@interfaces/update-personal-info.interface'

export class ParentPayload {
  private pId: string
  private pBillingForm: FormGroup
  private pParentForm: FormGroup
  private pPersonalForm: FormGroup
  private pTutorForm: FormGroup[]

constructor(
    id: string,
    billingForm: FormGroup,
    parentForm: FormGroup,
    personalForm: FormGroup,
    tutorForm: FormGroup[],
  ) {
    this.pId = id
    this.pBillingForm = billingForm
    this.pParentForm = parentForm
    this.pPersonalForm = personalForm
    this.pTutorForm = tutorForm
  }

  toJSON(): IParentSchoolsPayload {
    return {
      school_ids: [
        this.pId
      ]
    }
  }

  toUpdateJSON(): IUpdateParent {
    const form = this.pParentForm

    return {
      profile: {
        first_name: form.get('name').value,
        first_surname: form.get('lastNameF').value,
        second_surname: form.get('lastNameM').value,
        cellphone: `57${form.get('cellphone').value}`,
        addressInfo: {
          department: form.get('department').value.name,
          city: form.get('city').value.name
        }
      }
    }
  }

  updatePersonalInfo(): any {
    return {
      profile: {
        first_name: this.pPersonalForm.get('name').value,
        first_surname: this.pPersonalForm.get('lastNameF').value,
        second_surname: this.pPersonalForm.get('lastNameM').value,
        parent_type: +this.pPersonalForm.get('parentType').value,
        stratum: +this.pPersonalForm.get('stratum').value,
        sisben_applicant: this.pPersonalForm.get('sisbenApplicant').value,
        sisben_score: 0,
        addressInfo: {
          address: this.pPersonalForm.get('address').value,
          address_reference: this.pPersonalForm.get('reference').value,
          city: this.pPersonalForm.get('city').value,
          department: this.pPersonalForm.get('department').value,
          location: this.pPersonalForm.get('location').value,
          town: this.pPersonalForm.get('neighborhood').value
        }
      }
    }
  }

  createBillingInfo(): IBillingInfo {
    return {
      invoice_address: {
        address: {
          address: this.pBillingForm.get('address').value,
          address_reference: this.pBillingForm.get('reference').value,
          city: this.pBillingForm.get('city').value,
          department: this.pBillingForm.get('department').value,
          location: this.pBillingForm.get('location').value,
          town: this.pBillingForm.get('neighborhood').value,
        },
        email: this.pBillingForm.get('email').value,
        first_name: this.pBillingForm.get('name').value,
        document_type: this.pBillingForm.get('docType').value,
        last_name_m: this.pBillingForm.get('lastNameM').value,
        last_name_f: this.pBillingForm.get('lastNameF').value,
        invoice_manager: this.pBillingForm.get('billingManager').value
      }
    }
  }

  /** HACK: Move this payload to its own class to prevent this parameter */
  createTutors(form): any {
    const formData = new FormData()

    if (form.get('parent_id').value) {
      formData.append('tutor[parent_id]', form.get('parent_id').value);
    }

    formData.append('tutor[cellphone]', form.get('cellphone').value);
    formData.append('tutor[email]', form.get('email').value);
    formData.append('tutor[emergencyContact]', form.get('emergencyContact').value);
    formData.append('tutor[first_name]', form.get('name').value);
    formData.append('tutor[first_surname]', form.get('lastNameF').value);
    formData.append('tutor[second_surname]', form.get('lastNameM').value);
    formData.append('tutor[parent_type]', form.get('parentType').value);
    formData.append('tutor[addressInfo][address]', form.get('address').value);
    formData.append('tutor[addressInfo][address_reference]', form.get('reference').value);
    formData.append('tutor[addressInfo][city]', form.get('city').value.name);
    formData.append('tutor[addressInfo][department]', form.get('department').value.name);
    formData.append('tutor[addressInfo][location]', form.get('location').value);
    formData.append('tutor[addressInfo][town]', form.get('neighborhood').value);
    formData.append('tutor[identityDocument][file]', form.get('document').value);
    formData.append('tutor[identityDocument][document_type]', form.get('docType').value);
    formData.append('tutor[identityDocument][document_number]', form.get('docNumber').value);

    return formData
  }
}
