import { IActivitie } from "@interfaces/activitie.interface"
import { at } from "lodash"

export class Activitie {

    desciption: string
    horarios: Horario[]
    pricing: string
    pricing_externo: string
    image_url: string
    state:string
    id:string
    month_summer:string
    single_payment:boolean

    constructor(attrs?: IActivitie) {
        if (!attrs) return
        this.desciption = attrs.desciption
        this.horarios = this.mapHorarios(attrs.horarios)
        this.pricing = attrs.pricing
        this.pricing_externo = attrs.pricing_externo
        this.image_url = attrs.image_url
        this.state=attrs.state
        this.id=attrs.id
        this.month_summer=attrs.month_summer
        this.single_payment=attrs.single_payment
        
    }

    private mapHorarios(horarios: any) {
        return horarios.map((horario: any) => {
            return new Horario(horario);
        })
    }
}


export class Horario {
    days:string
    hora: string
    valido: string

    constructor(attrs?: any) {
        if (!attrs) return
        this.days=attrs.days
        this.hora = attrs.hora
        this.valido = attrs.valido
    }

}
