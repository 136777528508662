import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Session } from '@models/session';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  createSession(session: Session) {
    let headers = new HttpHeaders()
    return this.httpClient.post(`${environment.API}/login`,
      {
        session: {
          email: session.email.toLowerCase(),
          password: session.password
        }
      },
      { headers: headers }
    )
  }

  /**
  *The function uses the code sent from Cognito when the user
  *enter the system through the Federated option (Google or Facebook)
  *and get the respective security tokens.
  * 
  * @param code 
  */
  exchangeAuthCodeToken(code: string) {

    localStorage.setItem("federated", "true");

    let url = environment.cognito_endpoint + environment.cognito_oauth2;
    let clientId = environment.clientId;
    let appEnpoint = environment.app_endpoint;

    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    let body = `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${appEnpoint}`;

    return this.httpClient.post(url, body, { headers });
  }

}
