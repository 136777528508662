import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { IActivitie } from "@interfaces/activitie.interface";
import { IExtraService } from "@interfaces/extra.interface";
import { Activitie } from "@models/activitie";
import { ExtraService } from "@models/extra";
import { AnyRecord } from "dns";
import { map } from "rxjs/operators";

/**
* @autor Adrian Avendano Concha
* @date 25/01/2022
*/
@Injectable({
    providedIn: 'root'
})
export class ExtrasService {

    constructor(private httpClient: HttpClient) { }



    /**
   * Obtener los servicios extracurriculares de cada sede
   * @autor Adrian Avendano Concha
   * @date 21/02/2022
   */
    //trae las extracurriculares inscritas de un estudiante
    getExtraServices(childid: string) {
        // console.log("estoy en getExtraServices de extra.service ")
        /*  let url = `assets/dummi/extra.json`; */
        let url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
        /*      let url = `${environment.API3}/AolExtracurriculares`; */
        url += `?child_id=${childid}`

        return this.httpClient.get(url).pipe(
            map((response: IExtraService[][]) => {
                return response[0].map((service: IExtraService) => {
                    // console.log("GET EXTRA IN EXTRA SERVICE", new ExtraService(service))
                    return new ExtraService(service)
                })
            }),
        )
    }
    //trae las extracurriculares en oferta
    getExtraServicesAll(childid: string) {
        //  let url = `assets/dummi/extra.json`; 
        let url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
        /*      let url = `${environment.API3}/AolExtracurriculares`; */
        url += `?child_id=${childid}`
        // console.log('si llego al extraservice')
        // console.log("url", url)
        return this.httpClient.get(url).pipe(
            map((response: IExtraService[][]) => {
                return response[1].map((service: IExtraService) => {
                    // console.log("GET EXTRA SERVICES ALL IN EXTRASERVICE", new ExtraService(service))
                    return new ExtraService(service)
                })
            }),
        )
    }

    //elimina los servicios extracurriculares de pensiones services de un estudiante 
    deleteExtraPensiones(payload: any) {
        // console.log("hi from delete extra pensiones for some reason")
        let url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
        return this.httpClient.delete(url, payload)
    }

    //trae todas las extracurriculares para el backoffice
    getExtrasBack() {
        let url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;
        // console.log('buscando extras en extraservices')
        return this.httpClient.get(url).pipe(
            map((response: IExtraService[]) => {
                return response.map((service: IExtraService) => {
                    // console.log("new ExtraService(service)", new ExtraService(service))
                    return new ExtraService(service)
                })
            }),
        )
    }
    postExtrasBack(payload:any) {       
        let url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;
        // console.log('creando extracurricular en extraservices')                
        return this.httpClient.post(url, payload)
    }
    putExtrasBack(payload:any) {       
        let url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;
        // console.log('actualizando extracurricular en extraservices')    
        return this.httpClient.put(url, payload)
    }
    //elimina los servicios extacurriculares de una actividad especifica a todos los estudiantes dado un mes en adelante
    deleteExtrasBack(parameters:any) {  
        // console.log("parameters from services",parameters)     
        let url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;
        // console.log('borrando servicios extracurricular en extraservices')  
            //    let elpe:any={"fecha": "7-2021", 
            //    "serviceName": "test_CORPAS_SABADO_10:00:00AM"};
            url += `?${parameters}`
        return this.httpClient.delete(url)
    }
    uploadImageExtraBack(formData: any){
        let url = `${environment.API_ENDPOINTS}/aolutilities/UploadImageExtracurriculares`;
       
        return this.httpClient.post(url, formData)
      }
    

}